/*----------------------------------------
  ## Search Popup
----------------------------------------*/
.td-search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
  width: 40%;
  z-index: 9999;
}

.td-search-popup .search-form {
  width: 35%;
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
}

.td-search-popup .search-form .form-group .form-control {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 54px;
  padding: 0 75px 0 25px;
  background: #f7f9fb;
}
.td-search-popup .search-form .form-group .form-control:hover,
.td-search-popup .search-form .form-group .form-control:focus,
.td-search-popup .search-form .form-group .form-control:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.td-search-popup .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 54px;
  border: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0;
  text-align: center;
  cursor: pointer;
  background: var(--main-color);
}
.td-search-popup .search-form .submit-btn:hover,
.td-search-popup .search-form .submit-btn:focus,
.td-search-popup .search-form .submit-btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;
  content: "";
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==),
    progress;
}

.body-overlay.active {
  visibility: visible;
  opacity: 0.97;
}

.mfp-zoom-in {
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}
.mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/************ single-inpur-inner ************/
.single-input-inner {
  margin-bottom: 20px;
  input {
    width: 100%;
    height: 50px;
    border: 0 !important;
    padding: 0 18px;
    border-radius: 6px;
    font-weight: 300;
    &::placeholder {
      color: #adadad;
    }
  }
  textarea {
    width: 100%;
    border: 0 !important;
    height: 140px;
    border-radius: 6px;
    padding: 14px 18px;
    font-weight: 300;
    &::placeholder {
      color: #adadad;
    }
  }
  .single-select {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 0 !important;
    border-radius: 6px;
    padding: 0 18px;
    margin-bottom: 20px;
    .list {
      width: 100%;
    }
  }
  &.style-border {
    input {
      border: 1px solid rgba(234, 225, 214, 0.7) !important;
      &::placeholder {
        color: #adadad;
      }
    }
    textarea {
      border: 1px solid rgba(234, 225, 214, 0.7) !important;
      background: transparent;
      &::placeholder {
        color: #adadad;
      }
    }
    .single-select {
      border: 1px solid rgba(234, 225, 214, 0.7) !important;
      color: #adadad;
    }
  }
  &.style-border-bottom {
    input {
      border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
      background: transparent;
      padding: 0;
      &::placeholder {
        color: #adadad;
      }
    }
    textarea {
      border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
      &::placeholder {
        color: #adadad;
      }
    }
    .single-select {
      border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
      background: transparent;
      color: #adadad;
    }
  }
  &.style-bg {
    input {
      background: #f7fafc;
      &::placeholder {
        color: #3f517e;
      }
    }
    textarea {
      background: #f7fafc;
      &::placeholder {
        color: #3f517e;
      }
    }
    .single-select {
      background: #f7fafc;
      color: #3f517e;
    }
  }
  &.style-bg-none {
    input {
      background: transparent;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
    textarea {
      background: transparent;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
    .single-select {
      background: transparent;
      color: #fff;
    }
  }
}

.contact-form-inner {
  padding: 0 30px 30px;
  border-radius: 7px;
  overflow: hidden;
  background-size: cover;
  .single-input-inner.style-border-bottom {
    input {
      border-bottom: 1px solid #fff !important;
      &::placeholder {
        color: #fff;
      }
    }
  }
}

.blog-comment-form {
  textarea {
    background: #fbfbfb;
    border: 1px solid #fbfbfb;
  }
}
